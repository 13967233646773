import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app/app';
import { initTrackEvent } from '@coverright/shared/analytics';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'use-http';
import { environment } from './environments/environment';

initTrackEvent('profile');

ReactDOM.render(
  <StrictMode>
    <SnackbarProvider preventDuplicate classes={{
      variantSuccess: 'success-snack'
    }} anchorOrigin={{horizontal: 'left', vertical: 'bottom'}} maxSnack={3}>
      <Provider url={environment.userFlowRestUrl}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </SnackbarProvider>
  </StrictMode>,
  document.getElementById('root')
);
