import * as React from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { ABVariant, UserTestContext } from '@coverright/shared/contexts';

interface MenuProps {
  activeUrl: string,
  env: any,
  onItemClick?: (url: string, title: string) => void,
}


function Content(props: MenuProps) {
  const [hovered, setHovered] = React.useState<any>();
  const {config, isTester} = React.useContext(UserTestContext);

  const clickHandler = (url: string, title: string) => {
    if ((window as any).track) {
      (window as any).track('Nav: Click main menu item', {item: title});
    }
    props.onItemClick ? props.onItemClick(url, title) : document.location.href = url;
  }

  return <Grid sx={{ml: {xs: '-20px', md: 0}, mt: {xs: 2, md: 0}}} container direction={'column'}>
    <Grid item>
      <Typography sx={{textTransform: 'uppercase', color: '#777'}} className={'bold fs-20 no-wrap'}>LEARN ABOUT MEDICARE</Typography>
      <Divider sx={{mt: 1}} />
    </Grid>
    <Grid container direction={'row'}>
      <Grid item xs={12} lg={6}>
        <Grid container direction={'column'}>
          <NavigationItem title={'Your CoverRight Dashboard'}
                          subtitle={'Your centralized dashboard to help you find the Medicare plan that is best for you.  Start your journey here'}
                          url={props.env.dashboardUrl}
                          onClick={clickHandler}
                          active={props.activeUrl === props.env.dashboardUrl}
                          setHovered={setHovered}
                          hovered={hovered}/>
          <NavigationItem title={'Guided Recommendation'}
                          subtitle={'Find out what combination of Medicare plans suits you best with our simple quiz'}
                          url={props.env.dashboardUrl + 'plan-type-suits'}
                          onClick={clickHandler}
                          active={props.activeUrl === props.env.dashboardUrl + 'plan-type-suits'}
                          setHovered={setHovered}
                          hovered={hovered}/>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container direction={'column'}>
          <NavigationItem title={'Learning center'}
                          subtitle={'Learn all about Medicare with our 101 course, short videos, podcasts, and live webinars'}
                          url={props.env.learningUrl}
                          onClick={clickHandler}
                          active={props.activeUrl === props.env.learningUrl}
                          setHovered={setHovered}
                          hovered={hovered}/>
        </Grid>
      </Grid>
    </Grid>
    <Grid item sx={{mt: 1}}>
      <Typography sx={{textTransform: 'uppercase', color: '#777'}} className={'bold fs-20 no-wrap'}>SHOP MEDICARE PLANS</Typography>
      <Divider sx={{mt: 1}} />
    </Grid>
    <Grid container direction={'row'}>
      <Grid item xs={12} lg={6}>
        <Grid container direction={'column'}>
          <NavigationItem title={'Medicare Advantage'}
                          subtitle={'Find an all in one Medicare plans that meets your healthcare needs'}
                          url={props.env.medicareAdvantageUrl}
                          onClick={clickHandler}
                          active={props.activeUrl === props.env.medicareAdvantageUrl}
                          setHovered={setHovered}
                          hovered={hovered}/>
          {/*<NavigationItem title={'Medicare Supplement'}
                          subtitle={'Stay in original Medicare and add coverage for the “gaps” not covered by the government'}
                          url={props.env.medigapUrl}
                          active={props.activeUrl === props.env.medigapUrl}
                          setHovered={setHovered}
                          hovered={hovered}/>*/}
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container direction={'column'}>
          {/*<NavigationItem title={'Prescription Drugs'}
                          subtitle={'Find a prescription drug plan that covers your medications'}
                          url={props.env.pdpUrl}
                          active={props.activeUrl === props.env.pdpUrl}
                          setHovered={setHovered}
                          hovered={hovered}/>*/}
          <NavigationItem title={'Medicare Supplement'}
                          subtitle={'Stay in original Medicare and add coverage for the “gaps” not covered by the government'}
                          url={props.env.medigapUrl}
                          onClick={clickHandler}
                          active={props.activeUrl === props.env.medigapUrl}
                          setHovered={setHovered}
                          hovered={hovered}/>
        </Grid>
      </Grid>
    </Grid>
    <Grid item sx={{mt: 1}}>
      <Typography sx={{textTransform: 'uppercase', color: '#777'}} className={'bold fs-20 no-wrap'}>PERSONALIZED OFFERS</Typography>
      <Divider sx={{mt: 1}} />
    </Grid>
    <Grid container direction={'row'}>
      <Grid item xs={12} lg={6}>
        <Grid container direction={'column'}>
          <NavigationItem title={'Your Custom Quotes'}
                          subtitle={'If you’ve spoken with your dedicated advisor. Click here to access your custom quotes your advisor has recommended'}
                          url={props.env.dashboardUrl + 'compare-offer'}
                          onClick={clickHandler}
                          active={props.activeUrl === props.env.dashboardUrl + 'compare-offer'}
                          setHovered={setHovered}
                          hovered={hovered}/>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Grid container direction={'column'}>
          <NavigationItem title={'Save on Your Prescriptions (GoodRx / Cost Plus Drugs)'}
                          subtitle={'See if you can save more on your prescriptions with our partners at GoodRx and Mark Cuban Cost Plus Drugs'}
                          url={props.env.offersUrl}
                          onClick={clickHandler}
                          active={props.activeUrl === props.env.offersUrl}
                          setHovered={setHovered}
                          hovered={hovered}/>
        </Grid>
      </Grid>
    </Grid>
    {(isTester && config.flexpa) && <>
      <Grid item sx={{mt: 1}}>
        <Typography sx={{textTransform: 'uppercase', color: '#777'}} className={'bold fs-20 no-wrap'}>YOUR INFORMATION</Typography>
        <Divider sx={{mt: 1}} />
      </Grid>
      <Grid container direction={'row'}>
        <Grid item xs={12} lg={6}>
          <Grid container direction={'column'}>
            {config.flexpa && <NavigationItem title={'Plan Utilization'}
                                                            subtitle={'Log in to your carrier account and access your plan utilization history including your Explanation of Benefits (EOB)'}
                                                            url={props.env.flexpaUrl}
                                                            onClick={clickHandler}
                                                            active={props.activeUrl === props.env.flexpaUrl}
                                                            setHovered={setHovered}
                                                            hovered={hovered}/>}
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container direction={'column'}>
            {config.compare_year_to_year && <NavigationItem title={'AEP plans'}
                                                                          subtitle={'Compare your current plan with upcoming in 2023 options'}
                                                                          url={props.env.dashboardUrl + 'year-to-year'}
                                                                          onClick={clickHandler}
                                                                          active={props.activeUrl === props.env.dashboardUrl + 'year-to-year'}
                                                                          setHovered={setHovered}
                                                                          hovered={hovered}/>}
          </Grid>
        </Grid>
      </Grid>
    </>}
  </Grid>;
}

export const NavigationMenu = React.memo(Content);

interface NavigationItemProps {
  title: string,
  subtitle: string,
  url: string,
  active: boolean,
  setHovered: (target: any) => void,
  hovered: any,
  onClick: (url: string, title: string) => void,
}

function NavigationItem(props: NavigationItemProps) {
  const ref = React.useRef(null);

  return <Grid item ref={ref}
               onClick={() => props.onClick(props.url, props.title)}
               className={'pointer'}
               sx={{
                 opacity: (props.hovered && props.hovered !== ref.current) ? 0.4 : 1,
                 transition: 'all 0.3s',
                 py: '5px',
                 px: '10px'
               }}
               onMouseEnter={(e: any) => props.setHovered(e.currentTarget)}
               onMouseLeave={() => props.setHovered(undefined)}>
    <Box sx={{
      paddingX: '12px',
      pt: '5px',
      pb: '15px',
      borderRadius: '4px',
      border: props.active ? '1px solid #5ECB9E' : '1px solid white',
      backgroundColor: props.active ? 'rgba(2, 144, 148, 0.05)' : ''
    }}>
      <Typography className={'fs-20 bold'}>{props.title}</Typography>
      <Typography sx={{fontSize: {xs: 14, md: 16}}}>{props.subtitle}</Typography>
    </Box>
  </Grid>;
}

