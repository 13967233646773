import React from 'react';
import { CRInfo, PageWrapper } from '@coverright/ui/shared';
import { useHistory } from 'react-router-dom';
import * as QueryString from 'query-string';
import { CreateAccountForm } from '@coverright/features/account-creation';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { getStoredUser, isStoredUserFilled, resetStoredUser } from '@coverright/utils';
import { Box } from '@mui/material';
import { ProfileSource } from '@coverright/data-access/types/enrollment';

const formName = 'personal';

export function Personal() {
  const history = useHistory();
  const {keycloak, getHash} = React.useContext(KeycloakContext);
  const params = QueryString.parse(document.location.search);
  const [formik, setFormik] = React.useState<any>();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (keycloak?.authenticated) {
      resetStoredUser();
      document.location.href = (sessionStorage.getItem('redirectUrl') || document.referrer) + getHash();
    }
  }, []);

  React.useEffect(() => {
    const fields = sessionStorage.getItem('fields');
    if (!isStoredUserFilled(fields ? JSON.parse(fields) : undefined)) {
      history.push('/info')
    }
  }, []);

  const onBackButtonClick = () => {
    if (!isStoredUserFilled()) {
      history.push('/info')
    } else {
      resetStoredUser();
      document.location.href = document.referrer + getHash();
    }
  }

  const onSuccess = () => {
    setTimeout(() => {
      resetStoredUser();
      document.location.href = (sessionStorage.getItem('redirectUrl') || document.referrer) + getHash();
    }, 1000)
  }

  return (
    <PageWrapper title={'Create your account to continue…'}
                 subtitle={'Save your progress and join over 7,000 other people who have also used our platform'}
                 formName={formName}
                 loading={loading}
                 nextButtonDisabled={loading}
                 onNextButtonClick={formik?.handleSubmit}
                 onBackButtonClick={onBackButtonClick}
    >
      <div>
        <Box sx={{maxWidth: 400, m: 'auto'}}>
          <CreateAccountForm onLoadingChange={setLoading}
                             columns={1}
                             onSuccess={onSuccess}
                             setFormik={setFormik}
                             source={(params.source as ProfileSource) || ProfileSource.UserFlowV2}
                             showPhone />
        </Box>
      </div>
      <div className={'h-10'} />
      <CRInfo open={false} title={'How this information is used'} wrapperSxProps={{maxWidth: '500px'}}
              description={<>Our platform will ask you to enter preferences in order to provide personalized recommendations and options. Creating an account allows us to save your progress. You can also pick up where you left off and/or access your plan recommendations again at a later point in time.</>} />
      <div className={'h-40'} />
    </PageWrapper>
  );
}

export default Personal;


