import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import { LoginResponse, MutationLoginArgs } from '@coverright/data-access/types/enrollment';
import { enrollmentClient } from '@coverright/data-access/apollo-clients';

export function useLogin(options?: MutationHookOptions<{ login: LoginResponse }, MutationLoginArgs>) {
  return useMutation<{ login: LoginResponse }, MutationLoginArgs>(
    gql(summaryQuery),
    {
      ...options,
      client: enrollmentClient,
    }
  );
}


const summaryQuery = `
mutation ($password: String!, $username: String!) {
  login(password: $password, username: $username) {
    accessToken
    idToken
    refreshToken
  }
}
`;
