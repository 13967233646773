import {
  getStoredUser,
  isStoredUserFilled, resetStoredUser, setStoredUser
} from '@coverright/utils';
import {PageWrapper, CRInfo} from '@coverright/ui/shared';
import React from 'react';
import { useHistory } from 'react-router';
import { KeycloakContext } from '@coverright/shared/keycloak';
import { Box } from '@mui/material';
import { CollectInfoForm } from '@coverright/features/account-creation';

const formName = 'info';

export function Info() {
  const history = useHistory();
  const {keycloak, getHash} = React.useContext(KeycloakContext);
  const [formik, setFormik] = React.useState<any>();
  const [isValid, setIsValid] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);

  React.useEffect(() => {
    if (keycloak?.authenticated) {
      resetStoredUser();
      document.location.href = (sessionStorage.getItem('redirectUrl') || document.referrer) + getHash();
    }
  }, [keycloak?.authenticated]);

  React.useEffect(() => {
    const fields = sessionStorage.getItem('fields');
    if (isStoredUserFilled(fields ? JSON.parse(fields) : undefined)) {
      history.push('/personal')
    }
  }, []);

  const onSubmit = React.useCallback((values: any) => {
    if (isValid) {
      setStoredUser({...getStoredUser(), ...values});
      history.push('/personal')
    }
  }, [isValid])

  return (
    <PageWrapper title={getStoredUser()?.firstName ? `${getStoredUser()?.firstName}, let’s first personalize your Medicare recommendation.` : 'We’re glad you’re here!'}
                 subtitle={'To get you the best plan options please answer the following questions:'}
                 formName={formName}
                 onNextButtonClick={formik?.submitForm}
                 onBackButtonClick={() => {
                   resetStoredUser();
                   document.location.href = document.referrer + getHash()
                 }}
    >
      <div>
        <Box sx={{maxWidth: 400, m: 'auto'}}>
          <CollectInfoForm onValidChange={setIsValid} direction={'column'} setFormik={setFormik}
                           fields={urlParams.has('fields') ? urlParams.getAll('fields') as string[] : undefined}
                           onSubmit={onSubmit} />
        </Box>
      </div>
      <CRInfo open={false}  wrapperSxProps={{maxWidth: '500px'}} title={'How this information is used'}
              description={<>Private Medicare plan availability and prices can be impacted by your location, age, gender, and health status. Your answers to these questions will provide the most accurate rates and recommendations. <br /> <br />Your information is protected in a secure HIPAA-compliant data platform.</>} />
      <div className={'h-40'} />
    </PageWrapper>
  )
}

export default Info;
