import { ApolloProvider } from '@apollo/client';
import { client } from '@coverright/data-access/apollo-clients';
import { KeycloakProvider, withHashAuth, withKeycloakContext } from '@coverright/shared/keycloak';
import { ThemeProvider } from '@mui/material';
import { AppTheme } from '@coverright/ui/themes';
import React, { Suspense } from 'react';
import { useLocation, useHistory, useRouteMatch, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { IntercomProvider, Layout, NavigationMenu, Preloader } from '@coverright/ui/shared';
import { getStoredUser, setStoredUser } from '@coverright/utils';
import moment from 'moment';
import { Gender } from '@coverright/data-access/types/medigap';
import { initTrackEvent } from '@coverright/shared/analytics';
import Personal from './pages/personal/personal';
import Info from './pages/info/info';
import { HelpModal } from '@coverright/features/account-creation';
import { LoginWidget } from '@coverright/features/login';
import { ProfileSource } from '@coverright/data-access/types/enrollment';
import { withAdvisorContext, withUserTestContext, withAppContextProvider } from '@coverright/shared/contexts';
import * as _ from 'lodash';
import { environment } from '../environments/environment';


export function App() {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setStoredUser({
      ...getStoredUser(),
      quoteId: urlParams.get('quoteId') || getStoredUser()?.quoteId,
      medigapQuoteId:
        urlParams.get('medigapQuoteId') || getStoredUser()?.medigapQuoteId,
      zip: urlParams.get('zip') || getStoredUser()?.zip,
      countyName: urlParams.get('countyName') || getStoredUser()?.countyName,
      state: urlParams.get('state') || getStoredUser()?.state,
      firstName: urlParams.get('firstName') || getStoredUser()?.firstName,
      lastName: urlParams.get('lastName') || getStoredUser()?.lastName,
      tobacco: urlParams.get('tobacco') || getStoredUser()?.tobacco,
      birthDate:
        urlParams.get('birthDate') && moment(urlParams.get('birthDate')).isValid()
          ? moment(urlParams.get('birthDate')).format('YYYY-MM-DD')
          : getStoredUser()?.birthDate,
      phoneNumber: urlParams.get('phone') || getStoredUser()?.phoneNumber,
      email: urlParams.get('email') || getStoredUser()?.email,
      gender: urlParams.has('gender')
        ? urlParams.get('gender') === 'F'
          ? Gender.F
          : Gender.M
        : getStoredUser()?.gender,
      profileSource: urlParams.get('profileSource') || getStoredUser()?.profileSource,
    });
    if (urlParams.has('product')) {
      initTrackEvent(urlParams.get('product') as string)
    }
    if (urlParams.has('redirectUrl')) {
      sessionStorage.setItem('redirectUrl', urlParams.get('redirectUrl') || document.referrer)
    }
    if (urlParams.has('fields')) {
      sessionStorage.setItem('fields', JSON.stringify(urlParams.getAll('fields')))
    }
    IntercomProvider.boot();
  }, []);


  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={AppTheme}>
        <Suspense fallback={<Preloader />}>
          <Layout
            helpModalComponent={HelpModal}
            SideComponent={() => <NavigationMenu activeUrl={environment.profileUrl} env={environment}/>}
            sideHeaderContent={
              <LoginWidget
                source={
                  (getStoredUser()?.profileSource as ProfileSource) ||
                  ProfileSource.UserFlowV2
                }
                createModalSubtitle={
                  'We’ll save your progress and email you a unique link to access your recommendations.'
                }
                createModalTitle={'Create your account'}
                redirectUrl={process.env.NX_USER_FLOW_URL + 'dashboard'}
              />
            }
            location={location}
            history={history}
            match={match}
          >
            <Switch>
              <Route path={'/info'} component={Info} />
              <Route path={'/personal'} component={Personal} />
              <Route exact path={'/'}>
                <Redirect to={'/info' + document.location.search} />
              </Route>
              <Route>
                <Redirect to={'/info' + document.location.search} />
              </Route>
            </Switch>
          </Layout>
        </Suspense>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default _.flowRight([withHashAuth, withKeycloakContext, withUserTestContext, withRouter, withAppContextProvider])(App)
